import React from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

export let socket;

export default () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  React.useEffect(() => {
    socket = io(`/?token=${token}`);

    socket.on("connection", () => {});
    socket.on("action", (action) => {
      console.log(action);

      dispatch(action);
    });
  }, []);

  return (<></>);
};

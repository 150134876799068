import React from "react";
import { Button, Input, message, Radio, Tag } from "antd";
import QRCode from "react-qr-code";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { qrc as qrcApi } from "../../apis";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default ({ action, onCancel }) => {
  const { invoice } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState(action && action[1]?.qrCode || "");
  const [mode, setMode] = React.useState("qr");

  const onCopy = () => {
    copy(action[1]?.qrCode);

    message.success("Copied!");
  };

  const masked = pan => {
    return pan.substring(0, 4) +
    " " +
    pan.substring(4, 6) +
    "** **** " +
    pan.substring(12, 16);
  };

  const onChange = async (value) => {
    setPayload(value);
    setLoading(true);

    try {
      await qrcApi.decrypt({
        payload: value,
        invoice: action[1]?._id
      });

    } catch (err) {
      message.error("Invalid QR Code!");
    }
  };

  React.useEffect(() => {
    if (invoice && invoice.invoiceStatus === "PAID") {
      onCancel(true);
    }

    if (invoice && invoice.invoiceStatus === "FAILED") {
      onCancel(true, invoice.errorMessage);
    }

  }, [invoice]);

  React.useEffect(() => {
    dispatch({
      type   : "general.invoice",
      payload: null
    });
    setPayload(action && action[1]?.qrCode || "");
    setLoading(false);
    setMode("qr");
  }, [action]);

  return (
    <Container>
      <Radio.Group value={mode} buttonStyle="solid" onChange={e => {
        dispatch({
          type   : "general.invoice",
          payload: null
        });

        if (e.target.value === "qr") {
          setPayload(action && action[1]?.qrCode || "");
        } else {
          setPayload("");
        }

        setLoading(false);
        setMode(e.target.value);
      }}>
        <Radio.Button value="qr">QR</Radio.Button>
        <Radio.Button value="scan">Scan</Radio.Button>
      </Radio.Group>

      {mode === "qr" ? (
        <>
          <div className="qrc-payload">
            <QRCode value={payload} />
          </div>
          <Input placeholder="QRC Payload" value={payload} suffix={<Button size="small" type="default" onClick={() => onCopy()}>Copy</Button>} disabled />
        </>
      ) : (
        <div className="qrc-payload">
          <Input.TextArea placeholder="Consumer Presented QR" value={payload} onChange={e => onChange(e.target.value)} rows={6} disabled={loading} />
        </div>
      )}

      {invoice?.pay && (
        <>
          {invoice.pay.paymentMethod === "CARD" ? <Tag>VISA: {masked(invoice.pay.cardNumber)}</Tag> : <Tag>{invoice.pay.bankName}: {invoice.pay.bankAccountNumber}</Tag>}
          <Button type="primary" loading={true}>Хүлээгдэж байна</Button>
        </>
      )}

    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  .qrc-payload {
    padding: 40px;
  }
`;
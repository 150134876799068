/* eslint-disable no-nested-ternary */
import React from "react";
import { Tag, Button, Input, DatePicker } from "antd";
import { invoice as invoiceApi } from "../../apis";
import { CustomTable, RowAction } from "../../components";
import { SearchOutlined } from "@ant-design/icons";
import { Filter } from "../../components/Design";
import { tugrug } from "../../utils";
import styled from "styled-components";
import moment from "moment";

export default React.forwardRef(({ simulator, onAction }, ref) => {
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: "",
    endDate  : "",
  });

  React.useEffect(() => {
    let timeout;

    if (timeout) clearTimeout(timeout);
    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      end_date  : e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query       : "",
      simulator_id: simulator?.id,
      start_date  : "",
      end_date    : "",
    });
  };

  const masked = (pan) => {
    return pan.substring(0, 4) + " " + pan.substring(4, 6) + "** **** " + pan.substring(12, 16);
  };

  React.useImperativeHandle(ref, () => ({
    reload() {
      myTableRef.current.reload();
    },
  }));

  return (
    <>
      <Filter
        extra={[
          <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
          <Button onClick={onClear}>Цэвэрлэх</Button>,
        ]}>
        <Input className='grid-item' prefix={<SearchOutlined />} value={query} style={{ width: "250px" }} placeholder='Хайлт хийх...' onChange={(e) => setQuery(e.target.value)} allowClear />
      </Filter>

      <TransactionTable
        size='small'
        ref={myTableRef}
        filters={filters}
        loadData={invoiceApi.list}
        rowKey={(record) => record.id}
        thead={() => (
          <thead className='ant-table-thead'>
            <tr>
              <th className='ant-table-cell' rowSpan={2}>
                №
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Төлбөрийн мэдээлэл
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Төлбөрийн дүн
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Дансны мэдээлэл
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Төлөв
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Огноо
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Үйлдэл
              </th>
            </tr>
          </thead>
        )}
        tbody={(row, index) => {
          return (
            <tbody key={index} className='ant-table-tbody'>
              <tr>
                <td className='ant-table-cell' rowSpan={2}>
                  {index + 1}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  <Tag>{row.invoiceCode}</Tag>
                  <div>{row.description}</div>
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {tugrug(row.amount)}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  <Tag color="blue">
                    <div>{row.beneficiary.bankCode}: {row.beneficiary.bankAccountNumber}</div>
                    {row.beneficiary.bankAccountName}
                  </Tag>
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {row.invoiceStatus === "PAID" ? <Tag color='green'>ТӨЛӨГДСӨН</Tag> : row.invoiceStatus === "REFUNDED" ? <Tag color='yellow'>БУЦААГДСАН</Tag> : <Tag color='red'>ТӨЛӨГДӨӨГҮЙ</Tag>}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {moment(row.invoiceStatusDate).format("YYYY.MM.DD HH:mm")}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  <RowAction
                    actions={{
                      qrc   : "QR мэдээлэл",
                      refund: "Буцаалт хийх",
                    }}
                    onClick={(key) => onAction(key, row)}
                  />
                </td>
              </tr>
            </tbody>
          );
        }}
      />
    </>
  );
});

const TransactionTable = styled(CustomTable)`
  .ant-table-thead > tr > th {
    padding: 8px;
    line-height: 15px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
  }
`;

import React from "react";
import * as Yup from "yup";
import { Button, message as notify, Form as AntForm, Tag } from "antd";
import { Formik } from "formik";
import { FormItem, Input, Form, InputNumber, Select } from "formik-antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { bankAccount as bankAccountApi, invoice as invoiceApi } from "../../apis";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";

const FormSchema = () => Yup.object({
  description    : Yup.string().required("Заавал бөглөнө!"),
  amount         : Yup.string().required("Заавал бөглөнө!"),
  bankAccount    : Yup.string().required("Заавал бөглөнө!"),
  transactionType: Yup.string().required("Заавал бөглөнө!")
});

export default ({ simulator, onCancel }) => {
  const [data] = React.useState({
    description    : undefined,
    amount         : undefined,
    bankAccount    : undefined,
    transactionType: "PURCHASE",
    ...(simulator || {}),
  });
  const [bankAccounts] = useFetch(bankAccountApi.select)([]);

  const onSubmit = async (data) => {
    let res = await invoiceApi.create(data);

    notify.success("Таны хүсэлт амжилттай!");
    onCancel(res);
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form {...formItemLayout}>
              <FormItem name="description" label="Гүйлгээний утга" required>
                <Input name="description" placeholder="Гүйлгээний утга" maxLength={255} />
              </FormItem>
              <FormItem name="amount" label="Гүйлгээний дүн" required>
                <InputNumber name="amount" placeholder="Гүйлгээний дүн" style={{ width: "100%" }}/>
              </FormItem>
              <FormItem name="bankAccount" label="Төлбөр хүлээн авах" required>
                <Select name="bankAccount" placeholder="Сонгох" allowClear>
                  {bankAccounts?.map((item, index) => (
                    <Select.Option key={index} value={item._id}>
                      <Tag>{item.bankName}: {item.bankAccountNumber}</Tag>
                      {item.bankAccountName}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="transactionType" label="Гүйлгээний төрөл" required>
                <Select name="transactionType" placeholder="Гүйлгээний төрөл">
                  <Select.Option value="PURCHASE">PURCHASE</Select.Option>
                  <Select.Option value="PAYMENT">PAYMENT</Select.Option>
                  <Select.Option value="TRANSFER">TRANSFER</Select.Option>
                </Select>
              </FormItem>
              <AntForm.Item {...tailFormItemLayout}>
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                <Button className="button" type="default" onClick={onCancel}>Болих</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  .ant-page-header {
    padding: 0 0 24px 0;
  }
  .ant-page-header-heading-title {
    font-size: 17px;
  }
`;
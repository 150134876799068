const initialState = {
  isMobile : false,
  simulator: null,
  banks    : [],
  invoice  : null
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "general.init": {
      const {
        simulator,
        banks
      } = action.payload;

      return {
        ...state,
        banks,
        simulator
      };
    }
    case "general.invoice": {
      return {
        ...state,
        invoice: action.payload
      };
    }
    default:
      return state;
  }
};

export default general;

import React from "react";
import * as Yup from "yup";
import { Button, message as notify, Form as AntForm } from "antd";
import { Formik } from "formik";
import { FormItem, Input, Form } from "formik-antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { invoice as invoiceApi } from "../../apis";
import styled from "styled-components";

const FormSchema = () => Yup.object({
  refund_reason: Yup.string()
});

export default ({ action, onCancel }) => {
  const [data] = React.useState({
    refund_reason: ""
  });

  const onSubmit = async (data) => {

    console.log("#######", action);

    await invoiceApi.refund({
      ...data,
      id: action[1]._id
    });

    notify.success("Таны хүсэлт амжилттай!");
    onCancel("refund");
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form {...formItemLayout}>
              <FormItem name="refund_reason" label="Буцаалт хийх шалтгаан" required>
                <Input name="refund_reason" placeholder="Буцаалт хийх шалтгаан" maxLength={255} />
              </FormItem>
              <AntForm.Item {...tailFormItemLayout}>
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                <Button className="button" type="default" onClick={onCancel}>Болих</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  .ant-page-header {
    padding: 0 0 24px 0;
  }
  .ant-page-header-heading-title {
    font-size: 17px;
  }
`;
import React from "react";
import { Button, message, Modal } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { PlusOutlined } from "@ant-design/icons";
import Transaction from "../Transaction";
import InvoiceForm from "./InvoiceForm";
import QrCodeForm from "./QrCodeForm";
import { useDispatch } from "react-redux";
import RefundForm from "./RefundForm";
import { useSelector } from "react-redux";

export default () => {
  const transactionRef = React.useRef();
  const { invoice } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const [action, setAction] = React.useState([]);

  const onInvoice = () => {
    setAction(["invoice"]);
  };

  const onCancel = (reload, errorMessage) => {
    if (reload === true && errorMessage) {
      message.error(errorMessage);

      setAction([]);
      return transactionRef.current.reload();
    }

    if (reload === true) {
      message.success("Төлбөр амжилттай хийгдлээ!");

      setAction([]);
      return transactionRef.current.reload();
    }

    if (reload === "refund") {
      transactionRef.current.reload();
      setAction([]);
    } else if (reload) {
      transactionRef.current.reload();

      setAction(["qrc", reload]);
    } else {
      setAction([]);
    }
  };

  const onAction = (type, row) => {
    switch (type) {
      case "qrc": {
        dispatch({
          type   : "general.invoice",
          payload: null,
        });

        setAction(["qrc", row]);
        break;
      }
      case "refund": {
        setAction(["refund", row]);
        break;
      }
      default:
    }
  };

  React.useEffect(() => {
    if (invoice) {
      setAction([]);
      transactionRef.current.reload();

      dispatch({
        type   : "general.invoice",
        payload: null
      });
    }
  }, [invoice]);

  return (
    <PageContainer style={{ background: "#f7f7f7", height: "calc(100vh - 60px)" }}>
      <PageHeader title='Гүйлгээний мэдээлэл' extra={[<Button type='primary' shape='circle' onClick={() => onInvoice()} icon={<PlusOutlined />} />]} />

      <PageContent>
        {/* <pre>{JSON.stringify(action, null, 2)}</pre> */}
        <Transaction ref={transactionRef} onAction={onAction} />
      </PageContent>

      {action && action[0] === "invoice" && (
        <Modal title='Нэхэмжлэл бичих' visible={true} onCancel={() => onCancel()} footer={false}>
          <InvoiceForm onCancel={onCancel} />
        </Modal>
      )}

      {action && action[0] === "refund" && (
        <Modal title='Буцаалт хийх' visible={true} onCancel={() => onCancel()} footer={false}>
          <RefundForm action={action} onCancel={onCancel} />
        </Modal>
      )}

      <Modal title={action && action[1]?.description} visible={action && action[0] === "qrc"} onCancel={() => onCancel()} width={400} footer={false}>
        {action[1] && <QrCodeForm onCancel={onCancel} action={action} />}
      </Modal>
    </PageContainer>
  );
};
